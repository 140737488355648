import {sum} from "itertools"
import {ReactNode, useEffect, useMemo, useState} from "react"
import {range} from "remeda"
import {liveAtom, playerAtom, roundScoped} from "../../livestate/liveAtom"
import {$isHost, $playerIds, myId, PlayerId} from "../../livestate/liveContext"
import {hasAll, must} from "../../shared/utils/builtins"
import {atom} from "../../xignal/atom"
import {computed} from "../../xignal/computed"
import {useAtom, useOnceWhenHostAnd} from "../../xignal/react"
import {timeoutAtom} from "../../xignal/timeoutAtom"
import {HostButton, WaitingForOthers} from "../components/components"
import {Submission, SubmissionPlayerOverlay} from "../components/Submission"
import {
  $images,
  $round,
  $roundWinners,
  $scores,
  $submissions,
  finalRound,
  isDevMode,
  screenScoped,
  setScreen,
  startRound,
} from "../game"
import {useTimeLeft} from "../react/hooks"
import {cn} from "../utils/css"
import {setInterstitial} from "./interstitials"

const $timeLeft = screenScoped(liveAtom("ratingsTimeLeft", isDevMode() ? 9999 : 10))
const $ratings = screenScoped(playerAtom<number>("ratings"))
const $avgRating = screenScoped(liveAtom<number>("avgRating"))
const $winningRating = roundScoped(liveAtom<number>("winningRating", -1))
const $showCurtains = roundScoped(atom(false))
$avgRating.onChange((avgRating) => {
  if (avgRating != null) {
    $showCurtains.set(true)
  }
})
const MAX_STARS = 5

const $hasAllRatings = computed((watch) => hasAll(watch($ratings), watch($playerIds)))

function RatingsLayout({
  title,
  showTitle,
  children,
}: {
  title?: ReactNode
  showTitle?: boolean
  children?: ReactNode
}) {
  return (
    <div>
      <div
        className={cn(
          `font-bold pb-2 text-center ease-in transition-opacity ${twCurtainsDuration}`,
          !showTitle && " opacity-0",
        )}
      >
        {title || <>&nbsp;</> /* nbsp so it doesn't collapse layout */}
      </div>
      {children}
    </div>
  )
}

function useShowCurtains() {
  const showCurtains = useAtom($showCurtains)

  // For submissions after the first (unless you load the page directly there), $showCurtains starts
  // as true, then this sets it to false, so the css transition will work.
  useEffect(() => {
    // NB: We do want this delay here, but even if we didn't, we need at least a 0ms
    // setTimeout for the css transition to work properly
    setTimeout(() => {
      $showCurtains.set(false)
    }, 650)
  }, [])

  return showCurtains
}

export type RatingsScreenProps = {remainingIds: PlayerId[]}
export function RatingsScreen({remainingIds}: RatingsScreenProps) {
  const showCurtains = useShowCurtains()
  const myRating = useAtom($ratings.$mine)
  const avgRating = useAtom($avgRating)
  const playerId = remainingIds[0]!

  const [timerStart] = useState(() => $timeLeft.get())
  const timeLeft = useTimeLeft(timerStart, 1000, (timeLeft) => {
    if ($isHost.get()) {
      // Used above to initialize the timer for late joiners
      $timeLeft.set(Math.round(timeLeft))
    }
    if (timeLeft <= 0 && myRating == null) {
      $ratings.setItem(myId, 3)
    }
  })

  useOnceWhenHostAnd($hasAllRatings, () => {
    const avgRating = sum($ratings.values()) / $ratings.size
    $avgRating.set(avgRating)
    $scores.setItem(playerId, ($scores.getItem(playerId) ?? 0) + Math.round(avgRating * 10))
    const winningRating = $winningRating.get()
    if (
      avgRating > winningRating ||
      (avgRating === winningRating &&
        submittedEarlier(playerId, $roundWinners.mustGetItem($round.get()).playerId))
    ) {
      $winningRating.set(avgRating)
      $roundWinners.setItem($round.get(), {
        playerId,
        prompt: $submissions.getItem(playerId)?.text ?? "",
        image: $images.getItem(playerId) ?? {error: "Error loading image"},
      })
    }
    // FIXME LEFT OFF HERE: decide what to do about this.
    // - due to the delay, if host changes before then, all the stuff above could run again
    // (not a huge problem, but not ideal)
    // solution: check $hasAllRatings && !$avgRating
    // and have separate handler for $avgRating being set and timeout expiring
    // (or maybe it's just a timeout atom that gets set)
    // Or simplify by creating setHostTimeout API, and server waits for a response from the host
    setTimeout(() => {
      if (remainingIds.length > 1) {
        // NB: important not to mutate remainingIds here, it will mess up screenScoped
        ;[, ...remainingIds] = remainingIds
        // remainingIds = reverse(remainingIds)
        setScreen("RatingsScreen", {remainingIds})
      } else {
        setScreen("RoundWinnerScreen", {})
      }
    }, 5100) // NB: keep in sync with Curtains transition-delay + transition-duration + 100ms (extra 100 to avoid race conditions)
  })

  return (
    <RatingsLayout showTitle={!showCurtains} title={Math.ceil(timeLeft)}>
      <Submission playerId={playerId}>
        {avgRating != null && <RatingResult playerId={playerId} rating={avgRating} />}
        <Curtains show={showCurtains} />
      </Submission>
      {avgRating != null ? (
        ""
      ) : myRating != null ? (
        <WaitingForOthers />
      ) : (
        <div
          className={
            `flex gap-2 place-content-center -mt-0.5 ease-in transition-opacity ${twCurtainsDuration}` +
            (showCurtains ? " opacity-0" : "")
          }
        >
          {range(1, MAX_STARS + 1).map((numStars) => {
            return (
              <div
                key={numStars}
                className={
                  "border border-yellow-800 bg-yellow-200 rounded-md px-3 py-1.5 cursor-pointer"
                }
                onClick={() => {
                  $ratings.setItem(myId, numStars)
                }}
              >
                {numStars} ★
              </div>
            )
          })}
        </div>
      )}
      {isDevMode() && (
        <HostButton
          label="Clear rating"
          className="mt-4"
          onClick={() => {
            $avgRating.reset()
            $ratings.removeItem(myId)
          }}
        />
      )}
    </RatingsLayout>
  )
}
RatingsScreen.getKey = (props: RatingsScreenProps) => {
  return props.remainingIds[0]
}

export function RoundWinnerScreen(props: {}) {
  const showCurtains = useShowCurtains()
  const round = useAtom($round)
  const {playerId} = must(useAtom($roundWinners.$item(round)))
  const $timeout = useMemo(() => timeoutAtom(6000), [])

  useOnceWhenHostAnd($timeout, () => {
    if (round === finalRound) {
      setInterstitial("finalVote", "FinalVoteScreen", {})
    } else {
      startRound(round + 1)
    }
  })

  return (
    <RatingsLayout>
      <Submission playerId={playerId}>
        <SubmissionPlayerOverlay playerId={playerId}>
          <div className="text-3xl mt-2 align-bottom">Round {round + 1} Winner!</div>
        </SubmissionPlayerOverlay>
        <Curtains show={showCurtains} />
      </Submission>
    </RatingsLayout>
  )
}

const FRACTIONS = ["", " ¼", " ½", " ¾"]
function RatingResult({playerId, rating}: {playerId: PlayerId; rating: number}) {
  let wholeStars = Math.floor(rating)
  let quarterStars = Math.round((rating - wholeStars) * 4)
  if (quarterStars === 4) {
    wholeStars++
    quarterStars = 0
  }
  const fractionText = FRACTIONS[quarterStars]
  const stars = rating === 5 ? "⭐️⭐️⭐️⭐️⭐️" : "★".repeat(wholeStars) + fractionText

  return (
    <SubmissionPlayerOverlay playerId={playerId}>
      <div className="text-5xl mt-2 align-bottom">{stars}</div>
      {rating === 5 && <div className="text-3xl">Perfect Score!</div>}
    </SubmissionPlayerOverlay>
  )
}

const twCurtainsDuration = "duration-1000"
const twCurtains = `absolute w-1/2 inset-y-0 bg-[#2F0005] bg-cover transition-transform ${twCurtainsDuration}`
function Curtains({show}: {show: boolean}) {
  const twShow = "delay-[4s] ease-linear"
  const twHide = "ease-in"
  return (
    <>
      <div
        className={
          twCurtains + " left-0 bg-curtains-l " + (show ? twShow : twHide + " -translate-x-full")
        }
      />
      <div
        className={
          twCurtains + " right-0 bg-curtains-r " + (show ? twShow : twHide + " translate-x-full")
        }
      />
    </>
  )
}

function submittedEarlier(player1: PlayerId, player2: PlayerId) {
  for (const playerId of $submissions.keys()) {
    if (playerId === player1) return true
    if (playerId === player2) return false
  }
  throw Error("players not found in $submissions")
}
